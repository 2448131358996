import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification, useUser } from '../../stores/actions/StoreActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import CircularProgress from '@mui/material/CircularProgress';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

interface ProfileProps { }

const Profile: React.FC<ProfileProps> = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [sessionId, setSessionId] = useState(localStorage.getItem('cartinese_session_id'));
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { getUser, logoutUser } = useUser();


    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification();


    useEffect(() => {
        document.title = 'Profile';
        getUser();
    }, []);

    useEffect(() => {
        if (!user?.username) {
            navigate('/login');
        }
    }, [navigate, user]);

    const handleActivateCartinesePremium = () => {
        setIsLoading(true);
        if (!sessionId) {
            addNotificationError('Could not activate subscription. Please contact support.');
            return;
        }

        Client_.client_().activateCartiSubscription(sessionId).then(({ err, res }) => {
            setIsLoading(false);
            if (err) {
                addNotificationError(err);
            } else {
                getUser();
            }
        });
    };

    const handleLogout = () => {
        logoutUser();
        addNotificationSuccess('You are now logged out.');
        navigate('/');
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ width: 80, height: 80, mb: 2, bgcolor: 'primary.main' }}>
                    {user.first_name[0]}
                </Avatar>
                <Typography variant="h4" component="h1" gutterBottom>
                    {user.first_name}
                </Typography>
                <TextField
                    fullWidth
                    margin="normal"
                    id='username-field'
                    label='Username'
                    disabled
                    variant='outlined'
                    value={user.username}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    id='email-field'
                    label='Email'
                    disabled
                    variant='outlined'
                    value={user.email}
                />
                <Box sx={{ width: '100%', mt: 3, mb: 3 }}>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {user?.carti_premium?.premium_status ? (
                                    <CheckCircleIcon sx={{ color: 'success.main', mr: 1 }} />
                                ) : (
                                    <CancelIcon sx={{ color: 'error.main', mr: 1 }} />
                                )}
                                <Typography variant='body1'>Cartinese Premium</Typography>
                            </Box>
                            {user?.carti_premium?.premium_status ? (
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={() => navigate('/cartinese')}
                                    startIcon={<LaunchIcon />}
                                >
                                    Access
                                </Button>
                            ) : sessionId && !user?.carti_premium?.premium_status ? (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={handleActivateCartinesePremium}
                                    disabled={isLoading}
                                    startIcon={isLoading ? <CircularProgress size={20} /> : <LockOpenIcon />}
                                >
                                    {isLoading ? 'Activating...' : 'Activate'}
                                </Button>
                            ) : (
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={() => navigate('/cartinese/purchase')}
                                    startIcon={<ShoppingCartIcon />}
                                >
                                    Purchase
                                </Button>
                            )}
                        </Box>
                    </Paper>
                </Box>
                <Button
                    fullWidth
                    variant='outlined'
                    color='primary'
                    onClick={() => window.location.href = 'mailto:saarimmm@gmail.com'}
                    sx={{ mb: 2 }}
                    startIcon={<EmailIcon />}
                >
                    Contact Support
                </Button>
                <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={handleLogout}
                    startIcon={<ExitToAppIcon />}
                >
                    Logout
                </Button>
            </Paper>
        </Container>
    );
};

export default Profile;