import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from './components/Link';
import { getProducts } from './components/Product/Product';
import ProductGrid from './components/ProductGrid/ProductGrid';

import './App.css';

function App() {
  const navigate = useNavigate();

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: '#ffffff', // You can customize this
          },
          secondary: {
            main: '#cccccc', // You can customize this
          },
          background: {
            default: '#000000',
            paper: '#111111',
          },
        },
        typography: {
          fontFamily: 'Roboto, Arial, sans-serif',
          h2: { // Example of customizing heading styles
            fontWeight: 700,
            letterSpacing: '-0.02em', // Add a subtle letter spacing
          },
        },
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                backgroundColor: '#111111',
                borderRadius: '8px',
                boxShadow: '0px 4px 12px rgba(255, 255, 255, 0.1)', // Enhanced box shadow
                transition: 'transform 0.2s ease, box-shadow 0.2s ease', // Add transitions
                '&:hover': {
                  transform: 'translateY(-4px)', // Subtle hover lift
                  boxShadow: '0px 8px 20px rgba(255, 255, 255, 0.15)', // More pronounced shadow on hover
                },
              },
            },
          },
          MuiTypography: { // Example of global Typography customization
            variants: [
              {
                props: { variant: 'body1' },
                style: {
                  lineHeight: 1.8, // Improve readability
                },
              },
            ],
          },
        },
      }),
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: '#000000',
          display: 'flex', // Center content vertically
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="lg" sx={{ textAlign: 'center' }}> {/* Center text within the container */}
          <Box py={8}>
            <Typography variant="h2" component="h1" gutterBottom sx={{ color: '#ffffff', mb: 4 }}>
              Hi there!
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ color: '#cccccc' }}>
              I'm Saarim, and I love building amazing things.
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: '#ffffff' }}>
              Discover more about me on my{' '}
              <Link to="https://saarim.me">
                homepage
              </Link>.
            </Typography>
            <ProductGrid products={getProducts()} />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;